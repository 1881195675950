
.box {
    width: 600px;
    height: 390px;
}
    
   
    .flex{
        display: flex;
        gap: 30px;
    }
    .profile-img {
      background: #e8e8e8;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      overflow: hidden;
    }
    
    
    
    .skeleton-placeholder {
      background: linear-gradient(90deg, #e8e8e8 0px, #f8f8f8 40px, #e8e8e8 80px);
      background-size: 350px;
      width: 100%;
      height: 1.45rem;
      border-radius: 3px;
      margin-top: 1.5rem;
      animation: animation 2.5s infinite;
    }
  
  
  @keyframes animation {
    0% {
      background-position: -100px;
    }
    40%,
    100% {
      background-position: 270px;
    }
  }
  